import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import DatePicker from "vue2-datepicker";
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import psl from "psl";

import axios from "@/axios";
import rawAxios from "axios";

import {mapGetters} from "vuex";
import moment from "moment";
import AttributeHolder from "@/containers/add-entity-panel/panel/attribute-holder";
import {uuidv4, getConfig} from "@/utils/functions";
import VueCookies from "vue-cookies";
import {FEATURES} from "@/utils/constants";
import DropdownDatepicker from "@/containers/add-entity-panel/panel/attribute-holder/form-elements/dropdown-datepicker.vue";
import {EventBus} from "@/main.js";

export default {
    name: "Panel",
    components: {
        "v-select": vSelect,
        FilePond,
        multiselect: Multiselect,
        "attribute-holder": AttributeHolder,
        "neo-datepicker": DatePicker,
        DropdownDatepicker,
    },
    data() {
        return {
            minimizers: {
                relations: true,
            },
            date: new Date().getFullYear(),
            engine_google: true,
            engine_bing: false,
            engine_yandex: false,
            engine_yahoo: false,
            case_id: null,
            editMode: false,
            alert_variant: "success",
            alert_message: "",
            show_alert: false,
            minimized: false,
            raw_page_text: "",
            region: "United Kingdom",
            countResults: 30,
            searchDuration: 6,
            searchQuery: "",
            reference_urls: [],
            selected_key_facts: [],
            duplicateCard: false,
            updatedUserName: "",
            updatedPlatform: "",
            source_category_options: ["Social Media", "Open Source", "Risk Database", "Corporate Database", "Court Records"],
            source_category_value: [],
            country_options: [
                {value: "United Kingdom", text: "United Kingdom"},
                {value: "Spain", text: "Spain"},
                {value: "France", text: "France"},
                {value: "Italy", text: "Italy"},
                {value: "Brazil", text: "Brazil"},
                {value: "Germany", text: "Germany"},
                {value: "Dubai", text: "UAE"},
                {value: "New York", text: "US"},
                {value: "Singapore", text: "Singapore"},
                {value: "China", text: "China"},
                {value: "Japan", text: "Japan"},
                {value: "India", text: "India"},
            ],
            monitoring_types: [
                // {
                //     "value": "search_query_monitoring",
                //     "label": "Search Query",
                //     "description": "Search Query monitoring",
                //     "identifiers": false
                // },
                {
                    value: "url_page_monitoring",
                    label: "URL/Page",
                    description: "URL Page Monitoring",
                    identifiers: false,
                },
                // {
                //     "value": "site_monitoring",
                //     "label": "Site Monitoring",
                //     "description": "Site Monitoring",
                //     "identifiers": true
                // },
                {
                    value: "deep_web_frequency",
                    label: "Deep Web Search",
                    description: "Browser Automation Monitoring",
                    identifiers: true,
                },
            ],
            monitoring_type: null,
            line_options: [
                {
                    value: "solid",
                    icon: "icons_solid.svg",
                    description: "Solid",
                },
                {
                    value: "dashed",
                    icon: "icons_dotted.svg",
                    description: "Dashed",
                },
                {
                    value: "dotted",
                    icon: "icons_dotted.svg",
                    description: "Dotted",
                },
            ],
            line_direction: [
                {
                    value: "arrow_from",
                    icon: "icons_from.svg",
                    description: "Arrow from related entity to new entity",
                },
                {
                    value: "arrow_to",
                    icon: "icons_to.svg",
                    description: "Arrow from new entity to related entity",
                },
                {
                    value: "arrow_bi",
                    icon: "icons_bi-dir.svg",
                    description: "Bi-Directional arrow between new and related entity",
                },
                {
                    value: "arrow_none",
                    icon: "icons_non-dir.svg",
                    description: "Non-Directional line between new and related entity",
                },
            ],
            green_category_options: [
                {
                    value: "charitable_work",
                    label: "Charitable Work",
                },
                {
                    value: "volunteering_roles",
                    label: "Volunteering Roles",
                },
                {
                    value: "wider_evidence_skills",
                    label: "Wider Evidence of Skills",
                },
            ],
            green_category: {
                value: "charitable_work",
                label: "Charitable Work",
            },
            imageCaptionOptions: ["Subject's comment on post", "Subject's comments on post", "Subject shared post", "Friend's comment on post", "Friend's comments on post", "Profile username", "Subject tagged in post", "Subject's response to other user's question", "Other user's question to subject", "Subject's profile picture", "Subject's bio", "Liked page"],
            page_url: null,
            message: "Hello",
            entity: "",
            selectionData: {},
            entity_attributes: [],
            url_type: "website",
            fields: {},
            attributes: [],
            relations: [],
            relationshipTypes: [],
            research_notes: "",
            customer_notes: "",
            timeStamp: "",
            screenshot: {
                available: false,
            },
            // panel_type: "entity",
            add_to_graph: true,
            risk_categories: ["a", "b", "c", "d"],
            selected_categories: [],
            selectedSocialPlatform: {},
            platform: {
                platform: "",
                username: "",
                platform_name: "",
                icon: "",
            },
            social_platforms: [],
            entitiesRelationsAttributes: {
                entities: [],
                relations: [],
                attributes: [],
            },
            loader: null,
            save_for_continuous_monitoring: false,
            valid_social_profile: true,
            processingSaving: false,
            hide_save_button: false,
            imageUploading: false,
            imageUploadServer: {
                process: {
                    url: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1/uploads/reports/images`,
                    method: "POST",
                    headers: {
                        Authorization: "",
                    },
                },
                load: this.loadScreenshot,
            },
            api_base_url: "",
            api_username: "",
            api_password: "",
            myFiles: [],
            graph_icon: "",
            screenshot_caption: "",
            graph_label: "",
            status: "CONFIRMED",
            selected_rag_status: "Red",
            rag_status_options: ["Red", "Amber"],
            category_options: [],
            selected_category: null,
            // section_options: [],
            selected_section: null,
            publishedDate: null,
        };
    },
    methods: {
        addKeyFact(newKeyFact) {
            axios.post("/key-facts", {case_id: this.case_id, key_fact_name: newKeyFact}).then(async (response) => {
                await this.$store.dispatch("loadKeyFacts");
                // already selected option
                const saved = this.selected_key_facts.find((e) => e._id == response.data._id);
                if (!saved) {
                    const selectedNewOption = this.getKeyFacts.find((f) => f._id == response.data._id);
                    if (selectedNewOption) this.selected_key_facts.push(selectedNewOption);
                    else this.$toast.error("There was a problem saving new Outputs. Please try again!");
                }
            });
        },
        clearPublishedDate() {
            this.$refs["publishedDt"].$options.propsData.publishedDate = false;
            this.$refs["publishedDt"].$options.propsData.defaultDate = null;
            this.$refs["publishedDt"].year = null;
            this.$refs["publishedDt"].month = null;
            this.$refs["publishedDt"].day = null;
        },
        tempChange(e, field) {
            let temp = [];
            temp.push(e.year ?? "");
            temp.push(e.month ?? "");
            temp.push(e.day ?? "");
            const val = temp.join("-");
            this.publishedDate = val;
        },
        addRefURL() {
            this.reference_urls.push({url: ""});
        },
        deleteRefURL(index) {
            this.reference_urls.splice(index, 1);
        },
        addCaption(newTag) {
            this.imageCaptionOptions.push(newTag);
            this.screenshot_caption = newTag;
        },
        setStatus(data) {
            if (data == this.status) this.status = null;
            else this.status = data;
        },
        onValueChange(event, for_entity, relation) {
            if (for_entity) {
                this.entity_attributes = event;
            } else {
                relation.attributes_data = event;
            }
        },
        getArrowIcon(icon) {
            return require(`@/assets/icons/arrow-icons/${icon}`);
        },
        setLineType(val, relation) {
            relation.line_type = val;
        },
        setArrowType(val, relation) {
            relation.arrow_type = val;
        },
        addRelationshipType(newTag, relation) {
            let temp = {
                label: newTag,
                _id: uuidv4(),
            };
            this.relationshipTypes.push(temp);
            relation.type = temp;
        },
        relationTypeChange(value, relation) {
            relation.type.attributes = value.attributes;
        },
        showAlert(message, variant, duration) {
            this.makeToast(message, variant, duration);
            this.processingSaving = false;
            this.imageUploading = false;
            if (variant == "success") {
                this.hide_save_button = true;
                setTimeout(() => {
                    this.closeDialog();
                }, duration);
            }
        },
        makeToast(content, variant = "success", delay) {
            // this.$bvToast.toast(content, {
            //     title: "Alert",
            //     variant: variant || "default",
            //     solid: true,
            //     autoHideDelay: delay,
            //     appendToast: false,
            // });

            // FIXED FOR TECH-2000
            if (variant === "success") {
                this.$toast.success(content);
            } else if (variant === "danger") {
                this.$toast.error(content);
            } else this.$toast.show(content);
        },
        savePanelData() {
            if (this.selectionData.editEntity) {
                this.final_data.is_collapse = this.selectionData.all_data?.is_collapse ?? true;
                this.final_data.show_on_graph = this.final_data.show_on_graph;
                this.final_data.id = this.selectionData.all_data._id;

                if (this.selectionData.datatype == "entity") {
                    this.final_data.entity_identifier = "";
                    this.final_data.type = "";
                }

                delete this.final_data._id;

                if (this.final_data.status !== this.selectionData.all_data.status) delete this.final_data.confidence_score;
            }
            this.processingSaving = true;
            // if (this.final_data.monitoring == true && this.final_data.monitoring_type == null && this.panel_type != "search_engine") {
            //     this.showAlert("Please provide monitoring type.", "danger", 5000);
            //     return;
            // }

            if (!this.status) {
                this.$toast.error("Please select a status");
                this.processingSaving = false;
            } else {
                if (this.panel_type == "social_profile") {
                    if (this.final_data.notes == "") {
                        this.showAlert("Please enter research notes!", "danger", 5000);
                        return;
                    }
                    if (this.final_data.status == null) {
                        this.showAlert("Please provide a status!", "danger", 5000);
                        return;
                    }
                    axios
                        .post("add-social-profile", this.final_data)
                        .then((response) => {
                            this.reloadContent(["socialProfiles", "map"], true);
                            if (this.$store.getters.getFilteredData.socialProfiles.length > 0) {
                                this.$store.getters.getFilteredData.socialProfiles.map((el) => {
                                    if (el._id === response.data._id) {
                                        this.duplicateCard = true;
                                        this.updatedUserName = el.username;
                                        this.updatedPlatform = el.platform;
                                    }
                                });
                            }
                            if (response.data.status == false) this.showAlert(response.data.message, "danger", 3000);
                            else this.showAlert(response.data.message, "success", 3000);
                        })
                        .catch((err) => {
                            if (err.response) this.showAlert("Something went wrong!", "warning", 5000);
                        });
                } else if (this.panel_type == "key_fact") {
                    if (this.entity == "") {
                        this.showAlert("Please provide a Output title!", "danger", 5000);
                        return;
                    }
                    if (!this.selected_category) {
                        this.showAlert("Please select a category before saving!", "danger", 5000);
                        return;
                    }
                    if (!this.selected_section) {
                        this.showAlert("Please select a section before saving!", "danger", 5000);
                        return;
                    }
                    if (this.selectionData.editEntity) {
                        axios
                            .put("key-facts", {...this.final_data, id: this.selectionData.all_data._id})
                            .then((response) => {
                                this.reloadContent(["keyFacts", "map"], true);
                                this.showAlert(response.data.message, "success", 2000);
                            })
                            .catch((err) => {
                                if (err.response) {
                                    this.showAlert("Something went wrong!", "warning", 3000);
                                }
                            });
                    } else {
                        axios
                            .post("key-facts", this.final_data)
                            .then((response) => {
                                this.reloadContent(["keyFacts", "map"], true);
                                this.showAlert(response.data.message, "success", 2000);
                            })
                            .catch((err) => {
                                if (err.response) {
                                    this.showAlert("Something went wrong!", "warning", 3000);
                                }
                            });
                    }
                } else if (this.panel_type == "entity") {
                    let reloadType;
                    if (this.final_data.entity_type === "012da181-d04c-4ff3-ad01-a52fe9187686" || this.final_data.entity_type === "584b2f53-2694-439c-bef3-0390e43bc4d0") {
                        reloadType = ["associatedPeople", "map"];
                    } else {
                        reloadType = ["personalDetails", "map"];
                    }
                    this.processingSaving = false;
                    axios
                        .post("entity-identifier", this.final_data)
                        .then((response) => {
                            if (response.data.status === false) {
                                this.showAlert(response.data.message, "danger", 3000);
                            } else {
                                this.reloadContent(reloadType, true);
                                this.showAlert("Saved!", "success", 2000);
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                this.showAlert("Something went wrong!", "warning", 3000);
                            }
                        });
                } else if (this.panel_type == "active_media" || this.panel_type == "passive_media" || this.panel_type == "url" || this.panel_type == "search_engine") {
                    axios
                        .post("save-adverse-media", this.final_data)
                        .then(() => {
                            this.reloadContent(["media", "map"], true);
                            this.showAlert("Saved!", "success", 2000);
                        })
                        .catch((err) => {
                            if (err.response) {
                                this.showAlert("Something went wrong!", "warning", 2000);
                            }
                        });
                    if (this.panel_type == "search_engine") {
                        axios
                            .put("filter-params", {
                                case_id: this.case_id,
                                search_query: this.searchQuery,
                                monitoring_status: this.save_for_continuous_monitoring,
                            })
                            .then((_response) => {});
                    }
                }
            }
        },
        async loadScreenshot(source, load) {
            try {
                this.openImageURL = `https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/${source}`;
                let response = await rawAxios.get(this.openImageURL, {responseType: "blob"});
                load(response.data);
                this.showExpand = true;
            } catch (error) {
                this.openImageURL = "";
                load();
            }
        },
        addFile(error, file) {
            if (!error && !this.screenshot.available) {
                this.imageUploading = true;
            }
        },
        processFile(error, file) {
            if (!error) {
                this.screenshot.available = true;
                this.screenshot["location"] = JSON.parse(file.serverId).file;
                this.screenshot["file_name"] = file.filename;
                this.screenshot["file_mime_type"] = file.fileType;
                this.screenshot["size"] = file.fileSize;
                this.imageUploading = false;
            }
        },
        removeFile() {
            this.screenshot = {
                available: false,
            };
        },
        reloadContent(contentType, refreshFlag) {
            contentType.forEach((e) => {
                if (this.$route.name !== "research-automated") {
                    let data = {currentTab: e === "map" ? "personalDetails" : e};
                    this.$store.dispatch("fetchAllResearchScreenData", data);
                } else if (this.$route.name === "research-automated" && refreshFlag) {
                    EventBus.$emit("refreshResearchScreen");
                }
            });
        },
        closeDialog() {
            this.$store.dispatch("hideAddEntityPanel");
        },
        startScreenshot() {
            window.parent.postMessage({type: "hideSidepanelFrame"}, "*");
        },
        startFullScreenshot() {
            window.parent.postMessage({type: "hideFullSidepanelFrame"}, "*");
        },
        addRelation() {
            this.relations.push({
                entity: null,
                type: null,
                attributes: [],
                show_on_graph: true,
                line_type: "solid",
                arrow_type: "arrow_from",
                attributes_data: [],
                deleted: false,
                id: null,
            });
        },
        deleteRelation(relation) {
            relation.deleted = true;
            //this.relations.splice(index, 1)
        },
        getOptionIcon(option) {
            if (option.entity_type == "social_profile") {
                return this.$store.getters.getSocialPlatforms.find((e) => e.key == option.platform).icon_data;
            } else {
                try {
                    return this.$store.getters.getIconsData.find((e) => e.key == option.entity_identifier).icon_data;
                } catch {
                    return require("@/assets/add.svg");
                }
            }
        },
        searchEntities(query) {
            this.$store.dispatch("setEntitiesSearchQuery", query);
        },
        createSelectionData() {
            let reloadType;
            let selectionData = {
                text: "",
                entity: {
                    allow_relationship: true,
                },
                datatype: this.getSelectedEntityData.datatype,
                userDefined: true,
                editEntity: false,
            };
            if (this.getSelectedEntityData?.datatype == "entity" && this.getSelectedEntityData?.all_data && selectionData.editEntity == false) {
                if (this.getSelectedEntityData.all_data?.entity_type?._id === "012da181-d04c-4ff3-ad01-a52fe9187686" || this.getSelectedEntityData?.all_data?.entity_type?._id === "584b2f53-2694-439c-bef3-0390e43bc4d0") {
                    reloadType = ["associatedPeople", "map"];
                } else {
                    reloadType = ["personalDetails", "map"];
                }
                this.reloadContent(reloadType);
            }

            // { msg: "popupSidepanel", item: this_entity, datatype: "entity", edit: true, all_data: item }
            if (this.getSelectedEntityData.datatype == "entity") {
                selectionData.entity = this.getSelectedEntityData.entity;
                selectionData.related_to = this.getSelectedEntityData?.related_to ?? null;
                selectionData.relationType = this.getSelectedEntityData?.relationType ?? null;
            } else if (this.getSelectedEntityData.datatype == "social_profile" && (this.getSelectedEntityData?.editEntity ?? false == true)) {
                selectionData.entity = this.getSelectedEntityData.entity;
            } else if (["active_media", "passive_media", "url"].includes(this.getSelectedEntityData.datatype) && (this.getSelectedEntityData?.editEntity ?? false == true)) {
                selectionData.entity = this.getSelectedEntityData.entity;
            } else {
                selectionData.text = this.getSelectedEntityData.text;
                // selectionData.userDefined = false
            }
            if (this.getSelectedEntityData.datatype === "key_fact") {
                selectionData.all_data = this.getSelectedEntityData.all_data;
            }

            if (this.getSelectedEntityData?.editEntity) {
                selectionData.all_data = this.getSelectedEntityData.all_data;
                selectionData.editEntity = true;
                selectionData.userDefined = false;
            }

            if (!selectionData.editEntity && ["active_media", "passive_media", "url"].includes(this.getSelectedEntityData.datatype)) {
                selectionData.raw_page_text = document.querySelector("html").innerText;
            }

            return selectionData;
        },
        clearComponentData() {
            this.relations = [];
            this.attributes = [];
            this.relationshipTypes = [];
            this.selected_key_facts = [];
            this.reference_urls = [];
            this.myFiles = [];
        },
        initializeComponentData() {
            // TODO: Create entity fo edit here. IsEdit option may be taken from store
            this.clearComponentData();

            let selectionData = this.createSelectionData();
            let browserStore = {
                selectionData,
                case_id: this.getCaseId,
                entityRelationAttributes: this.getEntitiesRelationsAttributesData,
                api_base_url: `${process.env.VUE_APP_BLACK_BOX_URL}/api/v1/`,
                api_username: "",
                api_password: "",
            };

            this.api_base_url = browserStore.api_base_url;
            this.api_username = browserStore.api_username;
            this.api_password = browserStore.api_password;

            if (browserStore.selectionData.datatype === "key_fact") {
                // this.source_category_value = browserStore.selectionData?.all_data?.source_categories || [];
                // this.reference_urls = browserStore.selectionData?.all_data?.reference_urls || [];
                this.selected_category = this.category_options.find((op) => op.id === browserStore.selectionData?.all_data?.output_category_id);
                this.selected_section = this.section_options.find((sec) => sec.section_id === browserStore.selectionData?.all_data?.output_section_id);
            }

            if (!browserStore.selectionData.editEntity) {
                getConfig(["extension:research_notes_template", "extension:customer_notes_template"]).then((config) => {
                    if (config["extension:research_notes_template"].status && !["passive_media"].includes(browserStore.selectionData.datatype)) {
                        this.research_notes = config["extension:research_notes_template"].value;
                    }
                    if (config["extension:customer_notes_template"].status) {
                        this.customer_notes = config["extension:customer_notes_template"].value;
                    }
                });
                this.graph_label = browserStore.selectionData.text;

                if (["active_media", "passive_media", "url"].includes(browserStore.selectionData.datatype)) {
                    this.raw_page_text = browserStore.selectionData?.raw_page_text ?? "";
                }
                if (["passive_media", "url"].includes(browserStore.selectionData.datatype)) {
                    this.add_to_graph = false;
                }

                if (browserStore.selectionData.datatype == "entity" && browserStore.selectionData.related_to != null) {
                    let addRelationType = null;
                    if (browserStore.selectionData.relationType) {
                        addRelationType = browserStore.selectionData.relationType;
                    }
                    this.relations.push({
                        entity: browserStore.selectionData.related_to,
                        type: addRelationType,
                        attributes: [],
                        show_on_graph: true,
                        line_type: "solid",
                        arrow_type: "arrow_from",
                        attributes_data: [],
                        deleted: false,
                        id: null,
                    });
                }
            }

            const token = VueCookies.get("token");
            this.imageUploadServer.process.headers["Authorization"] = `Bearer ${token}`;

            this.selectionData = browserStore.selectionData;
            this.entity = browserStore.selectionData.text;
            this.case_id = browserStore.case_id;

            if (this.selectionData.datatype == "entity") {
                browserStore.selectionData.entity.attributes.forEach((e) => {
                    if (browserStore.selectionData.editEntity) {
                        this.$set(e, "value", browserStore.selectionData.all_data.attributes?.find((g) => g.attribute_id == e.attribute_id)?.value ?? "");
                    }
                });

                browserStore.selectionData.entity.relationship_types.forEach((e) => {
                    let t_relation = this.getEntitiesRelationAttributes?.relations?.find((f) => f._id == e.relation_id);
                    if (t_relation) {
                        this.relationshipTypes.push({
                            _id: t_relation._id,
                            label: t_relation.label,
                            direction: e.direction,
                            arrow_type: t_relation.arrow_type,
                            attributes: t_relation.attributes,
                        });
                    }
                });
            }

            if (browserStore.selectionData.editEntity) {
                if (["active_media", "passive_media", "url", "search"].includes(browserStore.selectionData.datatype)) {
                    this.entity = browserStore.selectionData.all_data.url;
                } else if (browserStore.selectionData.datatype === "key_fact") {
                    this.entity = browserStore.selectionData.all_data.key_fact_name;
                } else {
                    this.entity = browserStore.selectionData.all_data.entity;
                }
                this.publishedDate = browserStore.selectionData.all_data?.published_at;
                this.status = browserStore.selectionData.all_data?.status ?? "CONFIRMED";
                this.save_for_continuous_monitoring = browserStore.selectionData.all_data?.monitoring ?? false;
                this.add_to_graph = browserStore.selectionData.all_data?.show_on_graph ?? false;
                this.graph_label = browserStore.selectionData.all_data?.graph_label ?? "";
                this.screenshot_caption = browserStore.selectionData.all_data?.screenshot_caption ?? "";
                this.raw_page_text = browserStore.selectionData.all_data?.raw_page_text ?? "";
                this.selected_rag_status = browserStore.selectionData.all_data?.entity_status || "Red";
                this.selected_key_facts = browserStore.selectionData.all_data?.key_facts ?? [];

                if (this.screenshot !== "" && !this.imageCaptionOptions.includes(this.screenshot_caption)) {
                    this.imageCaptionOptions.push(this.screenshot_caption);
                }

                if (browserStore.selectionData.all_data.screenshot && browserStore.selectionData.all_data.screenshot.available) {
                    this.screenshot = browserStore.selectionData.all_data.screenshot;
                    this.myFiles = [{source: this.screenshot.location, options: {type: "local"}}];
                }

                let set_monitoring_type = browserStore.selectionData.all_data?.monitoring_type ?? null;
                let set_monitoring_option = this.monitoring_types.find((e) => e.value == set_monitoring_type);
                if (set_monitoring_option) {
                    this.monitoring_type = set_monitoring_option;
                }

                let set_risks = browserStore.selectionData.all_data?.risk_categories ?? [];
                let set_risk_options = [];
                set_risks.forEach((e) => {
                    let temp_option = this.getRiskCategories.find((f) => f.category == e);
                    if (temp_option) {
                        set_risk_options.push(temp_option);
                    }
                });
                this.selected_categories = set_risk_options;

                browserStore.selectionData.all_data?.relations &&
                    browserStore.selectionData.all_data.relations.forEach((e, i) => {
                        let isParentNode = false;
                        let related_entity = this.getEntitiesIdentifiers?.entities.find((f) => f._id == e.entity);
                        if (!related_entity) {
                            isParentNode = true;
                            related_entity = this.getEntitiesIdentifiers.main_node.find((f) => f._id == e.entity);
                        }

                        if (related_entity) {
                            let arrow_type;
                            if (browserStore.selectionData.all_data._id == e.relation_entity) {
                                if (e.arrow_type == "arrow_from") arrow_type = "arrow_to";
                                else if (e.arrow_type == "arrow_to") arrow_type = "arrow_from";
                                else arrow_type = e.arrow_type;
                            } else {
                                arrow_type = e.arrow_type;
                            }
                            let this_relation = {
                                entity: related_entity,
                                type: e.relation_type || [],
                                color: e?.color ?? "",
                                attributes: [],
                                show_on_graph: e.show_on_graph,
                                line_type: e.line_type,
                                arrow_type: arrow_type,
                                attributes_data: [],
                                deleted: false,
                                id: e._id,
                            };
                            if (isParentNode) {
                                this.relationTypeChange({attributes: e.attributes_data}, this_relation);
                            } else if ((related_entity?.status ?? null) == "CONFIRMED") {
                                this.relations.push(this_relation);
                                this.relationTypeChange({attributes: e.attributes_data}, this_relation);
                            }
                        }
                    });

                this.research_notes = this.selectionData.all_data?.notes ?? "";
                if (this.selectionData.all_data?.screenshot?.available) this.timeStamp = this.selectionData.all_data?.updated_at ?? "";
                this.customer_notes = this.selectionData.all_data?.customer_notes ?? "";
            }

            if (this.selectionData.datatype == "social_profile") {
                if (this.selectionData.editEntity == false) {
                    axios.post("parse-platform", {url: browserStore.selectionData.text}).then((response) => {
                        if (response.data.status) {
                            this.selectedSocialPlatform = this.getSocialPlatforms.find((e) => e.key == response.data.parse_platform.platform);
                            this.platform = response.data.parse_platform;
                            this.entity = response.data.parse_platform.username;
                            this.graph_label = response.data.parse_platform.username;
                            if (response.data.parse_platform.platform != "other") {
                                this.selectionData.userDefined = false;
                            }
                        } else {
                            this.selectedSocialPlatform = this.getSocialPlatforms.find((e) => e.key == "other");
                        }
                    });
                } else {
                    let current_platform = this.getSocialPlatforms.find((e) => e.key == this.selectionData.all_data.platform);
                    this.selectedSocialPlatform = current_platform;
                    this.platform = {
                        platform: this.selectionData.all_data.platform,
                        platform_name: current_platform.name,
                        icon: current_platform.icon_data,
                    };
                    this.entity = this.selectionData.all_data.username;
                }
            }

            if (this.selectionData.datatype == "continuous_monitoring") {
                this.save_for_continuous_monitoring = true;
            }

            this.page_url = location.href;
        },
        isMonitoringAuthorized(panel_type) {
            if (panel_type === "key_fact") return false;
            if (panel_type === "url") return (panel_type = "passive_media");
            return this.getPermissions.find((permiss) => FEATURES["add_panel_monitor"][permiss] == panel_type);
            // return this.getPermissions
        },
        setSection() {
            this.selected_section = null;
        },
        setCategorySection(getOutputTemplates) {
            this.category_options = getOutputTemplates.map((el) => ({name: el.category_name, sections: el.sections ?? [], id: el.category_id}));
        },
        async fetchSectionsAndcategory() {
            let componentInstance = this;
            if (componentInstance.getOutputTemplates?.length > 0) {
                componentInstance.setCategorySection(componentInstance.getOutputTemplates);
            } else {
                return new Promise((res) => {
                    const unwatchCategory = this.$watch("getOutputTemplates", () => {
                        componentInstance.setCategorySection(componentInstance.getOutputTemplates);
                        unwatchCategory();
                        res();
                    });
                });
            }
        },
        isProductMonitoringEnabled(type) {
            if (type === "entity") return false;
            const mapper = {
                passive_media: "active-passive-media",
                social_profile: "social-media",
                search_engine: "search-engine-tool",
                url: "url",
            };
            let monitoring_enabled_tools = this.$store.getters.productMonitoring;
            return !monitoring_enabled_tools.some((el) => el.tool === mapper[type] && el.status === "enabled");
        },
    },
    computed: {
        section_options() {
            if (!this.selected_category) return [];
            else {
                return this.selected_category.sections.filter((sec) => sec.active);
            }
        },
        searchEngine() {
            if (this.entity) {
                const parsedURL = new URL(this.entity);
                const parsedHost = psl.parse(parsedURL.hostname);
                const searchParams = parsedURL.searchParams;
                const valid = ["google", "bing", "yahoo", "yandex"].includes(parsedHost.sld);
                this.hide_save_button = !valid;
                if (valid) {
                    if (parsedHost.sld == "google") {
                        this.searchQuery = searchParams.get("q");
                    }
                    if (parsedHost.sld == "bing") {
                        this.searchQuery = searchParams.get("q");
                    }
                    if (parsedHost.sld == "yahoo") {
                        this.searchQuery = searchParams.get("p");
                    }
                    if (parsedHost.sld == "yandex") {
                        this.searchQuery = searchParams.get("text");
                    }
                    this.engine_google = parsedHost.sld == "google";
                    this.engine_yahoo = parsedHost.sld == "yahoo";
                    this.engine_bing = parsedHost.sld == "bing";
                    this.engine_yandex = parsedHost.sld == "yandex";
                } else {
                    this.searchQuery = "";
                    this.engine_google = false;
                    this.engine_yahoo = false;
                    this.engine_bing = false;
                    this.engine_yandex = false;
                }
                return {
                    engine: parsedHost.sld,
                    detected: valid,
                };
            } else {
                return {detected: true};
            }
        },
        panel_type() {
            return this.selectionData?.datatype ?? "entity";
        },
        final_data() {
            let data = {
                ...this.selectionData.all_data,
                ...{
                    screenshot: this.screenshot,
                    screenshot_caption: this.screenshot_caption,
                    case_id: this.case_id,
                    notes: this.research_notes,
                    customer_notes: this.customer_notes,
                    entity_attributes: this.entity_attributes,
                    show_on_graph: this.add_to_graph,
                    graph_icon: this.graph_icon,
                    graph_label: this.graph_label,
                    status: this.status,
                    entity_status: this.ragStatusRadio ? this.selected_rag_status || "Red" : "",
                    url: this.page_url,
                    risk_categories: this.selected_categories.map((e) => e.category),
                    monitoring: this.save_for_continuous_monitoring,
                    // monitoring_type: this.monitoring_type?.value ?? null,
                    is_adverse: this.selected_categories.filter((e) => e.category_type == "Risk Categories").length > 0,
                    key_facts: this.selected_key_facts.map((e) => ({key_fact_id: e._id})),
                    relations: this.relations.map((e) => {
                        return {
                            entity: e.entity?._id ?? "",
                            relation_type: e.type == null ? "" : e.type?._id ?? "",
                            show_on_graph: e.show_on_graph,
                            line_type: e.line_type,
                            arrow_type: e.arrow_type,
                            attributes_data: e.attributes_data,
                            color: e.color,
                            deleted: e.deleted,
                            _id: e.id,
                        };
                    }),
                    output_category_id: this.selected_category?.id ?? "",
                    output_section_id: this.selected_section?.section_id ?? "",
                },
            };
            if (this.panel_type == "entity" && this.selectionData.entity) {
                data.entity = this.entity;
                data.entity_type = this.selectionData.entity._id;
            } else if (this.panel_type == "social_profile") {
                data.username = this.entity;
                data.platform = this.selectedSocialPlatform.key;
            } else if (this.panel_type == "key_fact") {
                data.key_fact_name = this.entity;
                // data.source_categories = this.source_category_value;
                (data.output_category_id = this.selected_category?.id ?? ""), (data.output_section_id = this.selected_section?.section_id ?? "");
                data.reference_urls = this.reference_urls
                    .filter((e) => e.url.trim() != "")
                    .map((e) => {
                        return {url: e.url.trim()};
                    });
            } else if (this.panel_type == "active_media" || this.panel_type == "passive_media" || this.panel_type == "url") {
                data.media_type = this.panel_type;
                data.published_at = this.publishedDate;
                data.url = this.entity;
                data.raw_page_text = this.raw_page_text;
            } else if (this.panel_type == "search_engine") {
                data.media_type = "search";
                data.config = {
                    region: this.region,
                    countResults: this.countResults,
                    searchDuration: this.searchDuration,
                    engines: {
                        google: this.engine_google,
                        bing: this.engine_bing,
                        yahoo: this.engine_yahoo,
                        yandex: this.engine_yandex,
                    },
                };
            }
            return data;
        },
        notActivePassive() {
            return this.panel_type != "active_media" && this.panel_type != "passive_media" && this.panel_type != "search_engine" && this.panel_type != "social_profile";
        },
        getIcon() {
            if (this.panel_type == "entity" && this.selectionData && this.selectionData.entity) return require(`@/assets/tooltip-icons/${this.selectionData.entity.icon}`);
            switch (this.panel_type) {
                case "social_profile": {
                    let platform = this.getSocialPlatforms.find((e) => e.key == this.platform.platform);
                    return platform ? platform.icon_data : "";
                }
                case "active_media":
                    return require(`@/assets/tooltip-icons/icon_active.svg`);
                case "passive_media":
                    return require(`@/assets/tooltip-icons/icon_newspaper.svg`);
                case "key_fact":
                    return require(`@/assets/tooltip-icons/icon_keyfact.svg`);
                case "search_engine":
                    return require(`@/assets/tooltip-icons/search_mono.svg`);
                case "url":
                    return require(`@/assets/tooltip-icons/url.svg`);
                default:
                    break;
            }
            return "";
        },
        getIconLabel() {
            if (this.panel_type == "entity" && this.selectionData && this.selectionData.entity) return this.selectionData.entity.entity;
            switch (this.panel_type) {
                case "social_profile": {
                    let platform = this.getSocialPlatforms.find((e) => e.key == this.platform.platform);
                    return platform ? platform.name : "";
                }
                case "active_media":
                    return "Active Media";
                case "passive_media":
                    return "Media";
                case "key_fact":
                    return "Output";
                case "search_engine":
                    return "Search Engine Query";
                case "url":
                    return "URL";
                default:
                    return this.panel_type;
            }
        },
        getEntityData() {
            return this.entity;
        },
        getEntitiesRelationsAttributesData() {
            return this.$store.getters.getEntitiesRelationsAttributesData;
        },
        getSelectedEntityData() {
            return this.$store.getters.getSelectedEntityData;
        },
        getCategoriesList() {
            return this.$store.getters.getRiskCategories;
        },
        ragStatusRadio() {
            // return this.getCasePersonalData.case_type == "CS" && this.selected_categories.findIndex((n) => n.category_type == "Risk Categories") != -1;
            let categoriesList = this.getCategoriesList.find((cat) => cat.group === "Risk Categories");
            let radio = false;
            if (categoriesList?.categories) {
                radio = categoriesList?.categories.some((elem) => this.selected_categories.some((cat) => cat.category === elem.category));
            }
            return radio;
        },
        getEntitiesRelationAttributes() {
            return this.$store.getters.getEntitiesRelationsAttributesData;
        },
        getRiskCategories() {
            return this.$store.getters.getRawRiskCategories;
        },
        getEntitiesIdentifiers() {
            return this.$store.getters.getNodesInfo;
        },
        outputSection() {
            return this.panel_type == "key_fact" && (!this.selected_category || !this.selected_section);
        },
        ...mapGetters(["getCaseId", "getPermissions", "getSavedEntities", "getFilteredData", "getKeyFacts", "getCategorisedRiskCategories", "getSocialPlatforms", "getCasePersonalData", "getOutputTemplates", "getFilteredSavedEntities"]),
    },
    watch: {
        getSelectedEntityData() {
            this.initializeComponentData();
        },
    },
    async mounted() {
        this.$store.getters.productMonitoring.length > 0 ? "" : this.$store.dispatch("getProductMonitoring");
        await this.fetchSectionsAndcategory();
        this.initializeComponentData();
    },
};
